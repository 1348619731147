
import { computed } from "vue";
import { format, parseISO, isValid } from "date-fns";

export default {
	name: "CoreBlockArticleCard",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const articleSettings = computed(() => props.settings?.presetSettings);

		const articleAdapter = computed(() => {
			const articleMediaSrc = props.settings?.showMedia
				? articleSettings.value.url ||
				  articleSettings.value.webHostedAssetUrl
				: null;
			const headline = props.settings?.showHeadline
				? `<h4>${articleSettings.value?.headline}</h4>`
				: null;
			const bodyText = props.settings?.showBody
				? articleSettings.value?.bodyText
				: null;

			const parsedDate = parseISO(props.settings?.createdDt);
			const formattedDate = isValid(parsedDate)
				? format(parsedDate, "dd MMM yyyy")
				: props.settings?.createdDt;
			const overline =
				props.settings?.createdDt && props.settings?.showDatePublished
					? `date published ${formattedDate}`
					: null;

			const secondaryLabel =
				articleSettings.value?.page?.ctaText ?? "Read more";

			const secondaryUrl = {
				name:
					articleSettings.value?.page?.urlSlug?.toLowerCase() ??
					props.settings.title.toLowerCase().split(" ").join("-"),
			};

			return {
				...articleSettings.value,
				id: props.settings.id,
				type: "content-card",
				media: {
					src: articleMediaSrc,
					alt: articleSettings.value.altText,
				},
				overline,
				headline,
				bodyText,
				secondaryButton: true,
				secondaryBtnIcon: "arrow-right",
				secondaryBtnIconPlacement: "icon-right",
				secondaryBtnNewWindow: true,
				secondaryLabel,
				secondaryUrl,
				secondaryTypeText: true,
				variants: {
					cardStyle: "articles-section",
					"padding-x": "px-small",
				},
			};
		});

		const hideImage = computed(() => props.settings?.hideImage);

		return {
			articleSettings,
			articleAdapter,
			hideImage,
		};
	},
};
